<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <alert ref="Alert"></alert>
    <datatable-heading
      :title="pageTitle"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange"
    >
      <template v-slot:button>
        <b-button
          v-b-modal.modalright
          variant="primary"
          size="lg"
          :to="{
            name: 'add-sales-round-robin',
            params: { roundRobinTypeID: roundRobinTypeID }
          }"
          >Add New</b-button
        >
      </template>
    </datatable-heading>
    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="roundRobinID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
              <template slot="nonCorporateProgramInitials" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList
                    :linesToShow="2"
                    separator=","
                    :listText="props.rowData.nonCorporateProgramInitials"
                  />
                </div>
              </template>
              <template slot="corporateProgramInitials" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList
                    :linesToShow="2"
                    separator=","
                    :listText="props.rowData.corporateProgramInitials"
                  />
                </div>
              </template>
              <template slot="affiliates" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList
                    :linesToShow="2"
                    separator=","
                    :listText="props.rowData.affiliates"
                  />
                </div>
              </template>
              <template slot="states" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList
                    :linesToShow="2"
                    separator=","
                    :listText="props.rowData.states"
                  />
                </div>
              </template>
              <template slot="applicationTypes" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList
                    :linesToShow="2"
                    separator=","
                    :listText="props.rowData.applicationTypes"
                  />
                </div>
              </template>
              <template slot="active" slot-scope="props">
                <div>
                  <ToggleSwitch
                    v-on:input="flipActive(props.rowData)"
                    v-model="props.rowData.active"
                    :emitOnMount="false"
                    theme="custom"
                    class="vue-switcher-small"
                    color="primary"
                    :id="props.rowData.roundRobinTypeDescription"
                    v-b-tooltip.hover.left
                    :title="
                      `Activate/Deactivate ${props.rowData.roundRobinTypeDescription}`
                    "
                  />
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>
    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit')">
        <i class="simple-icon-docs" />
        <span>Edit</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('activate')">
        <i class="simple-icon-trash" />
        <span>Activate</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('deactivate')">
        <i class="simple-icon-trash" />
        <span>Deactivate</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>
<script>
import confirmation from '../../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../../../constants/config'
import moment from 'moment/moment.js'
import DatatableHeading from '../../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../../constants/VuetableBootstrapconfig'
import RoundRobinMixin from '../../../../../mixins/RoundRobinMixin.vue'
import { mapMutations } from 'vuex'
import switches from 'vue-switches'
import ReadMoreList from '../../../../../components/Listing/ReadMoreList.vue'
import alert from '../../../../../components/Common/Alert.vue'

export default {
  props: {
    roundRobinTypeID: {
      type: Number,
      default: 1
    }
  },
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation,
    ToggleSwitch: switches,
    ReadMoreList: ReadMoreList,
    Alert: alert
  },
  async created () {
    if (this.roundRobinTypeID === 1) {
      this.fields = this.fields.filter(({ name }) => name !== 'totalLeads')
    } else {
      this.fields = this.fields.filter(({ name }) => name !== 'totalCompanies')
    }
    this.pageTitle = this.roundRobinTypeID === 1 ? this.$t('menu.application-round-robin') : this.$t('menu.sales-round-robin')
    await this.getDefaultEmployeeForRoundRobinType(this.roundRobinTypeID).then(result => {
      if (result === '') {
        this.setWarningMessage('No default employee has been assigned for Round Robin.')
      }
    })
  },
  mixins: [RoundRobinMixin],
  data () {
    var _idfield = 'roundRobinID'
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/round-robin/table/${this.roundRobinTypeID}`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'number'
        },
        {
          name: 'employeeName',
          sortField: 'employeeName',
          title: 'Employee',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: '__slot:nonCorporateProgramInitials',
          sortField: 'nonCorporateProgramInitials',
          title: 'Programs',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: '__slot:corporateProgramInitials',
          sortField: 'corporateProgramInitials',
          title: 'Corporate Programs',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'text',
          visible: false
        },
        {
          name: '__slot:affiliates',
          sortField: 'affiliates',
          title: 'Affiliates',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: 'totalLeads',
          sortField: 'totalLeads',
          title: 'Total Leads',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'number'
        },
        {
          name: 'totalCompanies',
          sortField: 'totalCompanies',
          title: 'Active Applications',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'number'
        },
        {
          name: '__slot:states',
          sortField: 'states',
          title: 'States',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'text',
          visible: false
        },
        {
          name: '__slot:applicationTypes',
          sortField: 'applicationTypes',
          title: 'Application Types',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'text',
          visible: false
        },
        {
          name: '__slot:active',
          sortField: 'active',
          title: 'Active',
          titleClass: '',
          dataClass: 'text-muted',
          width: '8%'
        }
      ],
      pageTitle: '',
      rightClickedItem: null
    }
  },
  methods: {
    ...mapMutations([
      'setWarningMessage',
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    onContextMenuAction (action) {
      const { roundRobinTypeID = 0 } = this.rightClickedItem
      var matchedItems = this.$refs.vuetable.tableData.filter(item =>
        this.selectedItems.includes(item.roundRobinID)
      )
      var roundRobinNames = matchedItems
        .map(roundRobin => roundRobin.roundRobinTypeDescription)
        .join(', ')
      switch (action) {
        case 'edit': {
          if (this.selectedItems.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: 'You may only edit one item at a time',
              okButton: 'Cancel',
              exitFunction: e => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.refreshVueTable()
                }
              }
            })
          } else {
            this.$router.push({
              name: 'edit-sales-round-robin',
              params: {
                roundRobinID: this.selectedItems[0],
                roundRobinTypeID: this.roundRobinTypeID
              }
            })
          }
          break
        }
        case 'activate': {
          this.$refs.Confirmation.show({
            title: `Activate ${roundRobinTypeID === 1 ? 'Accreditation' : 'Sales'} Round Robin`,
            message: `Are you sure you want to activate this ${roundRobinTypeID === 1 ? 'Accreditation' : 'Sales'} round robin record?`,
            okButton: 'Activate'
          }).then(result => {
            if (result) {
              this.setInfoMessage('')
              this.activateItems(true, matchedItems)
            }
          })
          break
        }
        case 'deactivate': {
          this.$refs.Confirmation.show({
            title: `Deactivate ${roundRobinTypeID === 1 ? 'Accreditation' : 'Sales'} Round Robin`,
            message: `Are you sure you want to deactivate this ${roundRobinTypeID === 1 ? 'Accreditation' : 'Sales'} round robin record?`,
            okButton: 'Deactivate'
          }).then(result => {
            if (result) {
              this.setInfoMessage('')
              this.activateItems(false, matchedItems)
            }
          })
          break
        }
        default:
          break
      }
    },
    flipActive (roundRobin) {
      const roundRobinType = roundRobin.roundRobinTypeID === 1 ? 'Accreditation' : 'Sales'
      this.$refs.Confirmation.show({
        title: roundRobin.active
          ? `Activate ${roundRobinType} Round Robin`
          : `Deactivate ${roundRobinType} Round Robin`,
        message:
          'Are you sure you want to ' +
          (roundRobin.active ? 'activate' : 'deactivate') +
          ` this ${roundRobinType} round robin record?`,
        okButton: 'Yes',
        exitFunction: e => {
          if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
            this.refreshVueTable()
          }
        }
      }).then(async result => {
        if (result) {
          const roundRobinObj = [
            {
              ID: roundRobin.roundRobinID,
              active: roundRobin.active
            }
          ]
          this.setInfoMessage('')
          await this.activateRoundRobin(roundRobinObj).then(response => {
            this.refreshVueTable()
          })
        } else {
          this.refreshVueTable()
        }
      })
    },
    async activateItems (activateBool, matchedItems) {
      var activateRoundRobinList = matchedItems.map(roundRobin => ({
        ID: roundRobin.roundRobinID,
        active: activateBool
      }))
      await this.activateRoundRobin(activateRoundRobinList).then(response => {
        this.refreshVueTable()
      })
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MMM Do, YYYY')
      }
    }
  },
  computed: {}
}
</script>
