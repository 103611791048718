<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      roundRobin: {},
      statusCode: null,
      consultants: [],
      applicationTypes: []
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getRoundRobinById (roundRobinID) {
      return await axios
        .get(`${BeaconapiUrl}/api/round-robin/${roundRobinID}`)
        .then(result => {
          this.roundRobin = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async updateRoundRobin (roundRobin) {
      roundRobin.lastModifiedByID = localStorage.getItem('employeeID')

      await axios
        .put(`${BeaconapiUrl}/api/round-robin`, roundRobin)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(`Round Robin ${roundRobin.roundRobinID} Successfully Updated! `)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async addNewRoundRobin (roundRobin) {
      await instance
        .post(`${BeaconapiUrl}/api/round-robin`, roundRobin)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage('Round Robin Successfully Added!')
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAllAffiliatesConsultants () {
      await axios
        .get(`${BeaconapiUrl}/api/affiliate/consultant`)
        .then(result => {
          this.consultants = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAllRoundRobinApplicationtype () {
      await axios
        .get(`${BeaconapiUrl}/api/application/application-types`)
        .then(result => {
          this.applicationTypes = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async activateRoundRobin (roundRobin) {
      await axios
        .put(`${BeaconapiUrl}/api/round-robin/activate`, roundRobin)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    },
    // This function gets a list of all Sales Employees that do not currently have a round robin record,
    // plus the currently employee for the record being viewed (passed in as function params)
    async getAvailableSalesMembersForRoundRobin (employeeID, roundRobinType, roles) {
      var apiPath = ''
      const roleIds = roles.map((id) => `roleID=${id}`).join('&')
      if (employeeID) {
        apiPath = `${BeaconapiUrl}/api/authorization/role/employees?${roleIds}&hasRoundRobin=false&roundRobinType=${roundRobinType}&empIDToInclude=${employeeID}`
      } else {
        apiPath = `${BeaconapiUrl}/api/authorization/role/employees?${roleIds}&hasRoundRobin=false&roundRobinType=${roundRobinType}`
      }
      return await axios
        .get(apiPath)
        .then(result => {
          this.employees = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAvailableSalesMembersForAccreditationRoundRobin (employeeID, roundRobinType, roles) {
      const roleIds = roles.map((id) => `roleID=${id}`).join('&')

      var apiPath = ''
      if (employeeID) {
        apiPath = `${BeaconapiUrl}/api/authorization/role/employees?${roleIds}&hasRoundRobin=false&roundRobinType=${roundRobinType}&empIDToInclude=${employeeID}`
      } else {
        apiPath = `${BeaconapiUrl}/api/authorization/role/employees?${roleIds}&hasRoundRobin=false&roundRobinType=${roundRobinType}`
      }
      return await axios
        .get(apiPath)
        .then(result => {
          return result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    // Returns an EmployeeDTO of the default employee for the given roundRobinType.
    // Returns null if there is no default employee for that type.
    async getDefaultEmployeeForRoundRobinType (roundRobinTypeID) {
      return await axios
        .get(`${BeaconapiUrl}/api/round-robin/type/${roundRobinTypeID}/default-employee`)
        .then(result => {
          return result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
          return null
        })
    }
  }
}
</script>
